<template>
  <div>
    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn rounded disabled>{{ currentCenter.name }}</v-btn>
    </v-toolbar>
    <v-card class="ml-4">
      <table id="customers">
        <tr>
          <td><p>Class Name</p></td>
          <td>
            <p>{{ trainingClass.name }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Intake</p></td>
          <td>
            <p>{{ trainingClass.intake.name }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Trade</p></td>
          <td>
            <p>{{ trainingClass.training.name }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Trade Level</p></td>
          <td>
            <p>{{ trainingClass.trainingLevel.level }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Start Date</p></td>
          <td>
            <p>{{ trainingClass.startDate }}</p>
          </td>
        </tr>
        <tr>
          <td><p>End Date</p></td>
          <td>
            <p>{{ trainingClass.endDate }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Total Trainees</p></td>
          <td>
            <p>{{ trainingClass.numberOfTrainees }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Status</p></td>
          <td>
            <p>{{ trainingClass.state }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Comment</p></td>
          <td>
            <p>{{ trainingClass.comment }}</p>
          </td>
        </tr>
      </table>
    </v-card>
    <div v-if="showPanels">
      <v-data-table
        :headers="headers"
        :items="enrolments"
        hide-default-footer
        sort-by="name"
        class="elevation-1 ml-4"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-dialog v-model="dialog" max-width="1200px" persistent>
              <template v-slot:activator="{ on }">
                <v-spacer></v-spacer>
                <v-btn color="green" dark class="mb-2" v-on="on" small right
                  >Add Trainee</v-btn
                >
              </template>
              <v-card>
                <validation-observer ref="form">
                  <form @submit.prevent="onSubmit">
                    <v-card-title class="mb-12">
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" v-if="!edit">
                            <p class="font-weight-thin">Trainees/Delinquents</p>
                            <multiselect
                              v-model="selected"
                              placeholder="Select"
                              :options="delinquents"
                              :multiple="true"
                              :hideSelected="true"
                              :group-select="true"
                              track-by="groupedId"
                              label="groupedId"
                            ></multiselect>
                          </v-col>
                          <v-col cols="12" sm="6" v-if="edit">
                            <v-text-field
                              dense
                              label="Names"
                              outlined
                              v-model="editedTrainee.names"
                              class="mt-n6"
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="6" v-if="edit">
                            <v-select
                              :items="enrolmentStates"
                              dense
                              label="State"
                              outlined
                              v-model="editedTrainee.enrolmentState"
                              class="mt-n6"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" v-if="edit">
                            <v-select
                              :items="certificateTypes"
                              dense
                              label="Certificate Types"
                              outlined
                              v-model="editedTrainee.certificateType"
                              class="mt-n6"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" v-if="edit">
                            <v-select
                              :items="graduationEligibilityStates"
                              dense
                              label="Graduation EligibilityState"
                              outlined
                              v-model="editedTrainee.graduationEligibilityState"
                              class="mt-n6"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" v-if="edit">
                            <v-text-field
                              dense
                              label="Overall Mark"
                              outlined
                              type="number"
                              v-model="editedTrainee.overallMark"
                              class="mt-n6"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" v-if="edit">
                            <v-text-field
                              dense
                              label="State comment"
                              outlined
                              v-model="editedTrainee.enrolmentStateReason"
                              class="mt-n6"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="dialogbox">
                        <v-btn
                          color="green"
                          dark
                          class="ml-12 mt-n10"
                          type="submit"
                          >Save</v-btn
                        >
                        <v-btn class="ml-4 mt-n10" @click="close">Cancel</v-btn>
                      </div>
                    </v-card-actions>
                  </form>
                </validation-observer>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.names="{ item }">
          <p @click="switchPanel(item)" class="btn blue--text">
            {{ item.names }}
          </p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editTrainee(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="traineeDialog(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to remove this trainee from this class ?</p>
          <v-btn link small color="blue" @click="deleteEnrolment()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      showPanels: false,
      names: null,
      headers: [
        {
          text: 'Names',
          align: 'start',
          sortable: false,
          value: 'trainee.names'
        },
        { text: 'Year of Birth', value: 'trainee.dateOfBirth' },
        {
          text: 'District of Birth',
          value: 'trainee.placeOfBirth.district.name'
        },
        { text: 'Arrival Date', value: 'trainee.latestTransfer.arrivalDate' },
        { text: 'Intake', value: 'trainee.intake.name' },
        { text: 'Trade Status', value: 'enrolmentState' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      delinquents: [],
      enrolments: [],
      filterIntake: null,
      filterTraining: null,
      selected: null,
      editedTraineeIndex: -1,
      delinquentItem: null,
      transferId: null,
      errors: null,
      traineeIndex: null,
      traineeItem: null,
      edit: false,
      certificateTypes: ['COMPETENCE', 'PARTICIPATION', 'QUALIFICATION'],
      graduationEligibilityStates: ['ELIGIBLE', 'NOT_ELIGIBLE'],
      editedTrainee: {
        enrolmentState: null,
        certificateType: null,
        overallMark: null,
        graduationEligibilityState: null,
        enrolmentStateReason: null,
        trainee: {
          latestTransfer: {
            id: null
          }
        }
      },
      defaultTraineeItem: {
        enrolmentState: null,
        enrolmentStateReason: null,
        trainee: {
          latestTransfer: {
            id: null
          }
        }
      },
      enrolmentStates: ['COMPLETED', 'ENROLLED']
    }
  },
  components: {
    Multiselect
  },

  computed: {
    formTitle () {
      return this.editedTraineeIndex === -1
        ? 'Add Enrolment'
        : 'Edit Enrolment'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'storeProvinces',
      'trainingClass',
      'apprehensionItems'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.loadAlldelinquents()
    this.checkPrivileges()
    this.enrolments = this.trainingClass.enrolments
  },
  created () {
    this.showPanels = true
    this.privileges = this.currentUser.privileges
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('switchToClass'),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setTrainingClass: 'SET_TRAINING_CLASS'
    }),
    loadAlldelinquents () {
      this.$delinquentService
        .fetchAllTransfers(
          null,
          false,
          null,
          null,
          'REHABILITATION',
          null,
          '5000'
        )
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.namesDash =
                element.delinquent.firstName +
                '-' +
                element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(
                element.delinquent.dateOfBirth
              ).format('DD-MMM-YYYY')
              element.date = this.$moment(
                element.delinquent.dateOfBirth
              ).format('YYYY')
              element.groupedId =
                element.namesDash +
                '-' +
                element.date +
                '-' +
                element.delinquent.placeOfBirth.name
              element.arrivalDate = this.$moment(element.arrivalDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    enrolTrainees () {
      this.selected.forEach(element => {
        [
          'arrivalDate',
          'eligibilityState',
          'delinquent',
          'apprehension',
          'names',
          'namesDash',
          'groupedId',
          'date'
        ].forEach(e => delete element[e])
      })
      this.$delinquentService
        .enrolTrainees(this.trainingClass.id, this.selected)
        .then(response => {
          if (!response.message) {
            this.enrolments.push(response)
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    editTrainee (item) {
      this.editedTraineeIndex = this.enrolments.indexOf(item)
      this.edit = true
      this.editedTrainee.overallMark = item.overallMark
      this.editedTrainee.graduationEligibilityState =
        item.graduationEligibilityState
      this.editedTrainee.certificateType = item.certificateType
      this.editedTrainee.enrolmentState = item.enrolmentState
      this.editedTrainee.names = item.trainee.names
      this.editedTrainee.trainee.latestTransfer.id = item.trainee.latestTransfer
        .id
        ? item.trainee.latestTransfer.id
        : null
      this.editedTrainee.enrolmentStateReason = item.enrolmentStateReason
        ? item.enrolmentStateReason
        : null
      this.dialog = true
    },
    traineeDialog (item) {
      this.traineeIndex = this.enrolments.indexOf(item)
      this.traineeItem = item
      this.deleteDialog = true
    },
    deleteEnrolment () {
      this.deleteDialog = false
      this.$delinquentService
        .deleteEnrolment(
          this.trainingClass.id,
          this.traineeItem.trainee.latestTransfer.id
        )
        .then(response => {
          if (response.status === 'success') {
            this.enrolments.splice(this.traineeIndex, 1)
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.saveEnrolment()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },

    close () {
      this.edit = false
      this.dialog = false
      setTimeout(() => {
        this.editedTrainee = Object.assign({}, this.defaultTraineeItem)
        this.editedTraineeIndex = -1
      }, 300)
    },
    updateEnrolment () {
      const deletedTraineeArray = this.enrolments.splice(
        this.editedTraineeIndex,
        1
      )
      delete this.editedTrainee.names
      this.$delinquentService
        .updateTrainee(this.trainingClass.id, this.editedTrainee)
        .then(response => {
          if (!response.message) {
            response.trainee.names =
              response.trainee.firstName + ' ' + response.trainee.lastName
            response.trainee.intake = this.enrolments[
              this.editedTraineeIndex
            ].trainee.intake
            this.enrolments.splice(this.editedTraineeIndex, 0, response)
            this.close()
          } else {
            this.enrolments.splice(
              this.editedTraineeIndex,
              0,
              deletedTraineeArray[0]
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    saveEnrolment () {
      if (this.editedTrainee.enrolmentState) {
        this.updateEnrolment()
      } else this.enrolTrainees()
    },
    saveFromDate (fromDate) {
      this.$refs.fromDateMenu.save(fromDate)
    },
    saveToDate (toDate) {
      this.$refs.toDateMenu.save(toDate)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style scoped>
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers td p {
  margin-left: 30px;
}

#customers td:nth-child(odd) {
  font-weight: bold;
  width: 30%;
}

#customers td:nth-child(even) {
  width: 70%;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

.dialogbox {
  margin-bottom: 200px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
